import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ReportService } from '@/App/Services/Report.service'
import { Notification } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')

@Component
export default class TableHeader extends Vue {
  @sharedStore.Mutation setNotification: (notification: Notification) => void
  public isSearchActive = false
  public title = this.$t('userLogsPage.userLogsTable.tableHeader.usersLogsTitleText').toString()
  public actions: ActionBtn[] = [
    { icon: 'far fa-file-excel', tooltip: this.$t('userLogsPage.userLogsTable.tableHeader.exportToExcelTooltipText').toString(), method: () => this.onExport('excel') },
    { icon: 'far fa-file-pdf', tooltip: this.$t('userLogsPage.userLogsTable.tableHeader.exportToPdfTooltipText').toString(), method: () => this.onExport('pdf') },
    { icon: 'fa fa-print', tooltip: this.$t('userLogsPage.userLogsTable.tableHeader.onPrintTooltipText').toString(), method: () => this.onPrint() },
    { icon: 'fa fa-cog', tooltip: this.$t('userLogsPage.userLogsTable.tableHeader.logsSettingsTooltipText').toString(), event: 'openSettingsDialog' }
  ]

  public async onExport(type: 'excel' | 'pdf') {
    try {
      this.$emit('onLoading')

     await ReportService.download(type, this.title)
    } catch {} finally {
      this.$emit('onLoading')
    }
  }

  public async onPrint() {
    try {
      this.$emit('onLoading')

      await ReportService.print()
    } catch {} finally {
      this.$emit('onLoading')
    }
  }
}

interface ActionBtn {
  icon: string
  tooltip: string
  event?: string
  method?(): Promise<void>
}
