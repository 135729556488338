import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import TableHeader from './TableHeader/Table-Header.vue'
import UsersLogsSettingsDialog from './UsersLogsSettingsDialog/Users-Logs-Settings-Dialog.vue'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { UserLogSettings } from '@/App/Services/interfaces/user-log.interface'
import { ReportService } from '@/App/Services/Report.service'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { Report } from '@/App/Services/interfaces/report.interface'

const sharedStore = namespace('Shared')

@Component({
  components: { TableHeader, UsersLogsSettingsDialog }
})
export default class UserLogsTable extends Vue {
  @sharedStore.Mutation setNotification: (notification: Notification) => void
  public isLoading = false
  public search = ''
  public headers: VuetifyTableHeader[] = [
    { text: this.$t('userLogsPage.userLogsTable.loginText').toString(), align: 'center', sortable: true, value: 'login' },
    { text: this.$t('userLogsPage.userLogsTable.actionText').toString(), align: 'center', sortable: true, value: 'action' },
    { text: this.$t('userLogsPage.userLogsTable.infoText').toString(), align: 'center', sortable: true, value: 'text' },
    { text: this.$t('userLogsPage.userLogsTable.dateCreateText').toString(), align: 'center', sortable: true, value: 'dtCreate' }
  ]
  public reportsItems: Report[] = []

  public async onSettingsSubmit(settings: UserLogSettings) {
    try {
      this.isLoading = true

      this.reportsItems = await ReportService.fetch({
        type: 'auditTrail',
        ...settings,
        usersIds: settings.usersIds.join(','),
        logsTypes: settings.logsTypes.join(',')
      })
    } catch {} finally {
      this.isLoading = false
    }
  }
}
