import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LogsTypesSelection extends Vue {
  public logsTypes: string[] = []
  public logsTypesItems: string[] = [
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.loginText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.logOutText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.viewedNotificationText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.widgetCreatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.widgetUpdatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.widgetDeletedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.notificationCreatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.notificationDeletedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.notificationStatusChangedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.scheduleReportCreatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.scheduleReportDeletedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.scheduleReportStatusChanged').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.userCreatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.userDeletedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.userUpdated').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.userPasswordChanged').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.dashboardCreatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.dashboardDeletedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.dashboardUpdatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.dashboardSharedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.dashboardUnsharedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.geofenceCreatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.geofenceUpdatedText').toString(),
    this.$t('userLogsPage.userLogsTable.usersLogsSettingsDialog.logsTypesSelect.geofenceDeletedText').toString()
  ]

  onSelectAll() {
    this.logsTypes = this.logsTypesItems

    this.$emit('input', this.logsTypes)
  }
}
