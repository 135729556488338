import { Component, Vue } from 'vue-property-decorator'
import UserSelect from '@/App/Components/UserSelect/User-Select.vue'
import LogsTypesSelection from './LogsTypesSelection/Logs-Types-Selection.vue'
import { DateTimePicker } from '@/App/Components/Pickers'

@Component({
  components: { UserSelect, LogsTypesSelection, DateTimePicker }
})
export default class UsersLogsSettingsDialog extends Vue {
  public isOpen = false
  public settings: Settings = {
    usersIds: [],
    logsTypes: [],
    dateFrom: 0,
    dateTo: 0
  }

  public onSettingsSubmit() {
    this.$emit('onSettingsSubmit', {
      usersIds: this.settings.usersIds,
      logsTypes: this.settings.logsTypes,
      from: this.settings.dateFrom,
      to:  this.settings.dateTo
    })

    this.isOpen = false
  }
}

interface Settings {
  usersIds: string[]
  logsTypes: string[]
  dateFrom: number
  dateTo: number
}
